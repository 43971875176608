
import { apiService } from '../../../services/api.service'
import { Parameters } from './IParameters.interface'
import Swal from 'sweetalert2'
export default {
  computed: {
    parametersObjectToBeWatched () {
      return Object.assign({}, this.parameters)
    }
  },
  async created () {
    await this.getParameters()
  },
  data: () => ({
    valid: true,
    changedParameters: {},
    parameters: {
      healthCheckInterval: 0,
      updateCheckInterval: 0,
      gpuCheckInterval: 0,
      cordatusServicesHealthCheckInterval: 0,
      tunnelServerHealthCheckInterval: 0,
      signalServerHealthCheckInterval: 0,
      synchronizeAlarmsInterval: 0,
      checkLicenceValidityInterval: 0,
      isTunnelServerAuthorizedWithoutLicence: false,
      isSignalServerAuthorizedWithoutLicence: false,
      notificationStatus: ''
    },
    loading: false,
    rules: [(v) => !!v || 'Parameter is required']
  }),

  methods: {
    async getParameters () {
      try {
        this.loading = true
        const response = await apiService.get('/client-parameters')
        const parameters = response.data.parameters as Parameters
        for (const parameter in parameters) {
          this.parameters[parameter] = parameters[parameter]
        }
      } catch (error) {
        console.log('Error while getting parameters: ', error)
        Swal.fire('Warning!', 'Parameters could not be retrieved.', 'warning')
      } finally {
        this.loading = false
      }
    },
    async setChangedParameters () {
      await this.$store.dispatch('setChangedParameters', {
        ...this.changedParameters
      })
    },
    async updateParametersAndNotifyClients () {
      const shouldNotify = true
      await this.updateParameters(shouldNotify)
    },
    async updateParametersWithoutNotification () {
      const shouldNotify = false
      await this.updateParameters(shouldNotify)
    },
    async notifyClientsWithOldParameters () {
      try {
        await this.$store.dispatch('setChangedParameters', {
          ...this.parameters
        })
        const notificationConfig = await this.$store.dispatch('notifyClients', { notifyParameters: true })
        notificationConfig.status = 'Notifying'
        await this.$store.dispatch('updateNotificationStatus', { body: notificationConfig })
        await this.$store.dispatch('clearNonNotifiedParameters')
      } catch (error) {
        console.log('Error while notifying clients: ', error)
      } finally {
        this.getParameters()
      }
    },
    async updateParameters (shouldNotify = false) {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true
          await apiService.put('client-parameters', {
            ...this.changedParameters,
            notificationStatus: shouldNotify ? 'Notifying' : 'Pending'
          })
          await this.setChangedParameters()
          if (shouldNotify) {
            const notificationConfig = await this.$store.dispatch('notifyClients', { notifyParameters: true })
            notificationConfig.status = 'Notifying'
            await this.$store.dispatch('updateNotificationStatus', { body: notificationConfig })
            this.$store.dispatch('clearNonNotifiedParameters')
            Swal.fire(
              'Success!',
              'Parameters are saved and notification process is started.',
              'success'
            )
          } else {
            Swal.fire(
              'Success!',
              'Parameters are saved.\n To notify all the clients which are connected to Cordatus, please use the floating button in the middle right of the page.',
              'success'
            )
          }
        } catch (error) {
          console.log('Error while updating parameters: ', error)
          Swal.fire('Warning!', 'Parameters could not be saved.', 'warning')
        } finally {
          this.loading = false
          this.getParameters()
        }
      }
    },
    isNumber (value) {
      return typeof value === 'number'
    },
    getUpdatedParameter (newParameters: Parameters, oldParameters: Parameters) {
      for (const key in newParameters) {
        if (newParameters[key] !== oldParameters[key]) {
          if (!isNaN(parseInt(newParameters[key]))) {
            newParameters[key] = parseInt(newParameters[key])
          }
          return {
            [key]: newParameters[key]
          }
        }
      }

      return null // Return null if no differences found
    }
  },
  watch: {
    parametersObjectToBeWatched: {
      handler (newParameters: Parameters, oldParameters: Parameters) {
        delete newParameters.notificationStatus
        delete oldParameters.notificationStatus
        const cloneObj = JSON.parse(JSON.stringify(oldParameters))
        delete cloneObj.isSignalServerAuthorizedWithoutLicence
        delete cloneObj.isTunnelServerAuthorizedWithoutLicence
        // Check if all values of the oldParameters are 0 because of the initial page load
        const allOldParametersAreZero = Object.values(cloneObj).every(
          (value) => value === 0
        )
        if (allOldParametersAreZero) return
        const updatedParameter = this.getUpdatedParameter(
          newParameters,
          oldParameters
        )
        if (!updatedParameter) return
        this.changedParameters = {
          ...this.changedParameters,
          ...updatedParameter
        }
      },
      deep: true
    }
  }
}
