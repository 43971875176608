<template>
  <v-dialog v-model="showCreateDockerView" width="70vw" persistent>
    <v-card :loading="loading">
      <v-card-title>
        <span class="text-h6">{{ update ? 'Update' : 'New' }} Docker</span>
        <v-spacer></v-spacer>
        <v-btn @click="cancel" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-form
          lazy-validation
          v-model="form"
          ref="newDocker"
          :disabled="loading"
        >
          <v-card-title>
            <span class="text-h4 black--text font-weight-bold">Dockers</span>
          </v-card-title>
          <v-row>
            <v-col cols="12">
              <v-alert
                border="left"
                colored-border
                type="info"
                class="pb-0"
                transition="fade-transition"
              >
                <span>
                  Enter the first part of the address of the docker you want to
                  add without any Tag information.
                </span>
                <v-text-field
                  v-model="url"
                  outlined
                  class="mt-3"
                  prepend-icon="mdi-link"
                  label="URL of new docker"
                  clearable
                  :rules="rules"
                >
                </v-text-field>
              </v-alert>

              <v-text-field
                v-model="dockerName"
                label="Name of your new docker"
                clearable
                outlined
                :rules="rules"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-select
                v-model="type"
                :rules="rules"
                outlined
                clearable
                :items="types"
                label="Docker type"
              ></v-select>
            </v-col>

            <v-col cols="4">
              <v-combobox
                v-model="labels"
                outlined
                label="Labels about new docker"
                :rules="arrayRules"
                multiple
                hint="These labels will be used to provide better search results. (Click enter to add a new label)"
                persistent-hint
                clearable
                deletable-chips
                small-chips
                chips
              ></v-combobox>
            </v-col>
            <v-col cols="4">
              <v-select
                v-model="source"
                hide-details="auto"
                outlined
                :items="allSources"
                item-text="source_name"
                item-value="source_name"
                label="Sources of your docker"
                small-chips
                :rules="rules"
                deletable-chips
                chips
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div id="app">
                <vue-editor v-model="longDescription"></vue-editor>
              </div>
              <!-- <v-textarea
                v-model="description"
                class="mt-1"
                clearable
                label="Long and detailed description"
                outlined
                :rules="rules"
              ></v-textarea> -->
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="shortDescription"
                class="mt-1"
                clearable
                label="Short description"
                outlined
                :rules="rules"
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="roles"
                outlined
                :rules="arrayRules"
                :items="allRoles"
                item-text="name"
                item-value="id"
                label="Select roles of your docker"
                small-chips
                multiple
                clearable
                deletable-chips
                chips
              ></v-select>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <div v-if="dockersInfo">
            <v-card-title>
              <span class="text-h4 black--text font-weight-bold">Tags</span>
              <v-spacer></v-spacer>
              <v-text-field
                outlined
                dense
                label="Search Tags..."
                append-icon="mdi-magnify"
                hide-details
                clearable
                v-model="tagSearch"
              ></v-text-field>
              <v-btn icon @click="getTags">
                <v-icon> mdi-sync </v-icon>
              </v-btn>
              <v-btn color="primary" @click="editTag()" outlined class="ml-2">
                <v-icon left>mdi-plus</v-icon>Add New Tag
              </v-btn>
            </v-card-title>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="tagHeaders"
                  :items="tags"
                  :search="tagSearch"
                  :loading="tagLoading"
                >
                  <template v-slot:[`item.name`]="{ item }">
                    <span class="font-bigger">
                      {{ item.name }}
                    </span>
                  </template>
                  <template v-slot:[`item.label`]="{ item }">
                    <span class="font-bigger">
                      {{ item.label }}
                    </span>
                    <v-icon v-if="item.label === null">mdi-minus</v-icon>
                  </template>
                  <template v-slot:[`item.chipset_ids`]="{ item }">
                    <v-chip
                      class="ml-1 mb-1 elevation-1"
                      :key="index"
                      small
                      v-for="(chipset, index) in item.chipset_ids"
                    >
                      {{ shortenChipset(chipset) }}
                    </v-chip>
                    <v-icon v-if="item.chipset_ids.length === 0"
                      >mdi-minus</v-icon
                    >
                  </template>
                  <template v-slot:[`item.minDriverVersion`]="{ item }">
                    {{ item.min_driver_version }}
                    <v-icon v-if="!item.min_driver_version">mdi-minus</v-icon>
                  </template>
                  <template v-slot:[`item.jetpack_tag_assoc`]="{ item }">
                    <v-chip
                      class="ml-1 mb-1 elevation-1"
                      :key="index"
                      small
                      v-for="(jetpack, index) in getJetpackVersion(
                        item.jetpack
                      )"
                    >
                      {{ jetpack }}
                    </v-chip>
                    <v-icon v-if="item.jetpack.length === 0">mdi-minus</v-icon>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-btn icon @click="editTag(item)">
                      <v-icon>mdi-circle-edit-outline</v-icon>
                    </v-btn>
                    <v-btn icon @click="removeTag(item)">
                      <v-icon>mdi-delete-outline</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </div>
          <v-alert text type="info" v-else>
            Create docker before adding a new tag.
          </v-alert>
        </v-form>
      </v-card-text>
      <v-card-text class="d-flex justify-end">
        <v-btn text @click="clear" v-if="!update"> Clear </v-btn>
        <v-btn
          class="ml-5"
          :disabled="!form || loading"
          color="primary"
          :loading="loading"
          @click="saveData"
        >
          {{ update ? 'Update' : 'Save' }}
        </v-btn>
      </v-card-text>
    </v-card>
    <create-tag-view
      :tag="selectedTag"
      :dockerId="dockerId"
      :dockerUrl="url"
      :showCreateTagView="showEditTag"
      @close="showEditTag = false"
      :allJetpacks="allJetpacks"
      @tag-updated="getTags"
    ></create-tag-view>
  </v-dialog>
</template>

<script>
import { apiService } from '../../../services/api.service'
import CreateTagView from './CreateTagView.vue'
import converter from '../../mixins/converter'
import { map } from 'lodash'
import Swal from 'sweetalert2'
import { VueEditor } from 'vue2-editor'

export default converter.extend({
  components: { CreateTagView, VueEditor },
  mixins: [converter],
  data () {
    return {
      longDescription: '',
      tagsOverlay: false,
      zIndex: 0,
      types: ['app', 'docker'],
      dockerName: '',
      type: '',
      description: '',
      shortDescription: '',
      labels: '',
      roles: [],
      source: {},
      url: '',
      tags: [],
      dockerId: null,
      rules: [(v) => !!v || 'Field is required'],
      arrayRules: [(v) => v.length > 0 || 'Field is required'],
      form: true,
      loading: false,
      update: false,
      showEditTag: false,
      selectedTag: null,
      tagHeaders: [
        {
          text: 'Name',
          value: 'name',
          width: 250
        },
        {
          text: 'Label',
          value: 'label',
          align: 'center'
        },
        {
          text: 'Devices',
          value: 'chipset_ids',
          align: 'center',
          sortable: false
        },
        {
          text: 'Uncompressed Size (BYTE)',
          value: 'uncompressed_size',
          align: 'center',
          sortable: false
        },
        {
          text: 'Jetpack',
          value: 'jetpack_tag_assoc',
          align: 'center',
          sortable: false
        },
        {
          text: 'Min Driver Version',
          value: 'minDriverVersion',
          align: 'center',
          sortable: false
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center',
          sortable: false,
          width: 110
        }
      ],
      tagSearch: null,
      tagLoading: false
    }
  },
  props: {
    value: Boolean,
    allRoles: Array,
    allSources: Array,
    allDevices: Array,
    allJetpacks: Array,
    dockersInfo: {
      type: Object,
      default: () => null
    }
  },
  methods: {
    async getTags () {
      this.tagLoading = true
      await apiService
        .get(`admin/docker-tags/${this.dockersInfo.id}`)
        .then((res) => {
          this.tags = res.data.tags
        })
        .finally(() => {
          this.tagLoading = false
        })
    },

    saveData: function () {
      if (this.$refs.newDocker.validate()) {
        this.loading = true
        apiService
          .post(`admin/docker-${this.update ? 'update' : 'new'}`, {
            dockerId: this.update ? this.dockersInfo.id : null,
            dockerName: this.dockerName,
            type: this.type,
            desc: this.longDescription,
            shortDesc: this.shortDescription,
            labels: this.labels,
            rolesIds: this.roles,
            sourceId: this.source,
            url: this.url
          })
          .then(() => {
            Swal.fire(
              `${this.update ? 'Updated' : 'Created'}`,
              `Docker has been ${
                this.update ? 'updated' : 'created'
              } successfully.`,
              'success'
            )
            this.$store.dispatch('getDockers')
            this.cancel()
          })
          .catch(() => {
            Swal.fire('Deleted!', 'Docker could not created', 'error')
          })
          .finally(() => {
            this.loading = false
          })
      }
    },

    removeTag (selectedTag) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You wont be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!'
      }).then(async (result) => {
        const payload = {
          tagId: selectedTag.id
        }
        if (result.value) {
          await apiService
            .post('admin/delete-tag', payload)
            .then((res) => {
              this.getTags()
              Swal.fire('Deleted!', 'Licence has been deleted.', 'success')
            })
            .catch((err) => {
              console.error('Error at delete tag: ', err)
              Swal.showValidationMessage(`Request failed: ${err}`)
            })
        }
      })
    },

    editTag (tag) {
      this.selectedTag = tag
      this.showEditTag = true
    },

    clear () {
      this.$refs.newDocker.reset()
      this.$refs.newDocker.resetValidation()
    },

    cancel: function () {
      this.$emit('input', false)
    },
    getJetpackVersion (item) {
      return item.map((val) => {
        return val.version
      })
      // return get(item, 'jetpack_version', {}).version
    }
  },

  mounted () {
    if (this.dockersInfo) {
      this.update = true
      this.dockerId = this.dockersInfo.id
      this.dockerName = this.dockersInfo.docker_name
      this.type = this.dockersInfo.type
      this.longDescription = this.dockersInfo.desc
      this.shortDescription = this.dockersInfo.short_desc
      this.labels = this.dockersInfo.labels.split(',')
      this.url = this.dockersInfo.url
      this.roles = this.dockersInfo.roles.map((a) => {
        return a.id
      })
      this.source = this.dockersInfo.source.source_name
      this.tags = this.dockersInfo.tags
      this.getTags()
    }
  },
  computed: {
    showCreateDockerView: {
      get () {
        return this.value
      }
    }
  }
})
</script>
<style scoped>
.font-bigger {
  font-size: 14px !important;
}
</style>
