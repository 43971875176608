
import { apiService } from '../services/api.service'
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'

import jwtService from '../src/service/jwt.service'
import { createUrl } from './utils/helpers'
import environment from '../environment'
import NotificationSideMenu from './components/notify-clients/NotificationSideMenu.vue'

export default Vue.extend({
  name: 'App',
  components: {
    NotificationSideMenu
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'userInfo'])
  },
  data: () => ({
    drawer: false,
    isMobile: false,
    menu: [
      {
        icon: 'mdi-home-outline',
        label: 'Home',
        url: 'home',
        disable: true
      },
      {
        icon: 'mdi-account-group-outline',
        label: 'Users',
        url: 'users',
        disable: false
      },
      {
        icon: 'mdi-card-account-details-outline',
        label: 'Licences',
        url: 'licences',
        disable: false
      },
      {
        icon: 'mdi-cellphone-link',
        label: 'devices',
        url: 'devices',
        disable: false
      },
      {
        icon: 'mdi-docker',
        label: 'dockers',
        url: 'dockers',
        disable: false
      },
      {
        icon: 'mdi-lan-connect',
        label: 'connected clients',
        url: 'clients',
        disable: false
      },
      {
        icon: 'mdi-server',
        label: 'cordatus services',
        url: 'services',
        disable: false
      },
      {
        icon: 'mdi-tag',
        label: 'Version Management',
        url: 'versionManagement',
        disable: false
      },
      {
        icon: 'mdi-code-braces',
        label: 'Engines',
        url: 'engines',
        disable: false
      }
    ]
  }),
  beforeDestroy () {
    if (typeof window === 'undefined') return

    window.removeEventListener('resize', this.onResize)
  },
  beforeMount () {
    this.authcheck()
  },

  mounted () {
    this.onResize()

    window.addEventListener('resize', this.onResize, { passive: true })
  },

  methods: {
    onResize () {
      this.isMobile = window.innerWidth < 600
    },

    async authcheck () {
      const t = { ...mapMutations(['setAuthenticated', 'setUser']) }
      const res: any = await apiService.get('confirm-login/').catch(() => {
        this.$store.dispatch('setAuthenticated', false)
        return false
      })
      if (res) {
        this.$store.dispatch('setAuthenticated', true)
        this.$store.commit('setUser', res.data.user)
      }
    },
    toggleDarkTheme () {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
    },

    goToDashboard () {
      window.location.href = createUrl(
        environment.frontendSecure,
        environment.frontendUrl,
        environment.frontendPort
      )
    },

    async logout () {
      this.$store.dispatch('setAuthenticated', false)
      this.$store.commit('setUser', [])
      jwtService.destroyToken()
      this.goToDashboard()
    }
  }
})
