import { render, staticRenderFns } from "./CreateTagView.vue?vue&type=template&id=26ed3099"
import script from "./CreateTagView.vue?vue&type=script&lang=js"
export * from "./CreateTagView.vue?vue&type=script&lang=js"
import style0 from "./CreateTagView.vue?vue&type=style&index=0&id=26ed3099&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports