<template>
  <div>
    <v-bottom-sheet persistent v-model="userLicencesDialog">
      <v-card height="800">
        <template>
          <v-tabs v-model="tab">
            <v-tab v-for="(n, idx) in tabs" :key="`tab-${idx}`">
              {{ n.label }}
              <v-btn @click="closeTab(idx, 'devices')" icon v-if="n.canClose">
                <v-icon small>mdi-close</v-icon>
              </v-btn>
            </v-tab>
            <v-spacer></v-spacer>
            <v-btn @click="close" class="mr-2" icon>
              <v-icon medium>mdi-close</v-icon>
            </v-btn>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-card>
                <v-card-title color="rgba(0, 0, 0, 0)" flat>
                  <div>
                    <v-divider class="ml-2" vertical></v-divider>
                  </div>
                  <span class="font-weight-medium mr-2">User: </span>
                  <v-chip label color="indigo">
                    <span class="white--text">{{ currentUser.name }}</span>
                  </v-chip>
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="searchUserLicence"
                    class="mr-2"
                    clearable
                    dense
                    hide-details
                    label="Search licence.."
                    outlined
                    prepend-inner-icon="mdi-magnify"
                  ></v-text-field>
                  <v-spacer></v-spacer>
                  <v-btn @click="syncUserLicences" icon>
                    <v-icon dark>mdi-sync</v-icon>
                  </v-btn>
                  <v-btn
                    @click="openAssignLicenceDialog"
                    color="indigo"
                    class="mr-2 white--text"
                  >
                    <v-icon left> mdi-plus </v-icon>
                    Assign Licence
                  </v-btn>
                </v-card-title>

                <v-data-table
                  :headers="headers"
                  :items="userLicences"
                  :search="searchUserLicence"
                  :loading="getUserLicenceLoading"
                >
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          icon
                          color="indigo"
                          @click="upgradeLicence(item)"
                          ><v-icon>mdi-tag-arrow-up</v-icon></v-btn
                        >
                      </template>
                      <span>Upgrade Licence</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          icon
                          @click="deleteLicence(item)"
                          :disabled="item.label === 'Free'"
                          ><v-icon>mdi-delete-outline</v-icon></v-btn
                        >
                      </template>
                      <span>Delete Licence</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          color="blue-grey "
                          icon
                          @click="device_sheet(item)"
                          :disabled="item.devices.length < 1"
                          ><v-icon>mdi-cellphone-link</v-icon></v-btn
                        >
                      </template>
                      <span>See Devices</span>
                    </v-tooltip>
                  </template>

                  <template v-slot:[`item.label`]="{ item }">
                    <v-row align="center" class="d-flex flex-nowrap">
                      <v-col cols="4">
                        <span
                          style="max-width: 4vw"
                          class="font-16 d-inline-block text-truncate"
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <span v-bind="attrs" v-on="on">
                                <v-chip
                                  class="ml-2"
                                  small
                                  color="indigo lighten-1"
                                  dark
                                >
                                  {{ item.time_left }}
                                </v-chip>
                              </span>
                            </template>
                            Days Left
                          </v-tooltip>
                        </span>
                      </v-col>
                      <v-col cols="8">
                        <span
                          style="max-width: 4vw"
                          class="font-16 d-inline-block text-truncate"
                        >
                          {{ item.label }}</span
                        >
                      </v-col>
                    </v-row>
                  </template>

                  <template v-slot:[`item.created_at`]="{ item }">
                    <span>{{ timeParser(item.licence_keys.created_at) }}</span>
                  </template>

                  <template v-slot:[`item.limit`]="{ item }">
                    <span>{{ item.counter_usages + ' / ' + item.limit }}</span>
                  </template>

                  <template v-slot:[`item.started`]="{ item }">
                    <span>{{ timeParser(item.started) }}</span>
                  </template>

                  <template v-slot:[`item.features`]="{ item }">
                    <v-btn
                      @click="getFeatureLimit(item.features, item.label)"
                      small
                      color="primary"
                      dark
                      text
                      >See Features
                    </v-btn>
                  </template>

                  <template v-slot:[`item.generated_licences`]="{ item }">
                    <v-btn
                      @click="
                        getUserLicenceKeys(item.generated_licences, item.label)
                      "
                      small
                      color="secondary"
                      dark
                      text
                      >Licence Keys
                    </v-btn>
                  </template>

                  <template v-slot:[`item.subscriptions`]="{ item }">
                    <v-btn
                      @click="getSubscriptionDetails(item.subscriptions, item)"
                      small
                      color="grey darken-3"
                      dark
                      text
                      >Subscription Details
                    </v-btn>
                  </template>

                  <template v-slot:[`item.is_cancelled`]="{ item }">
                    <v-chip
                      v-if="!item.subscriptions.is_canceled"
                      label
                      small
                      color="green lighten-1 ml-2 mb-1"
                      ><span class="white--text">Active</span></v-chip
                    >

                    <v-chip v-else label small color="red lighten-1 ml-2 mb-1"
                      ><span class="white--text">Cancelled</span></v-chip
                    >
                  </template>

                  <template v-slot:[`item.endtime`]="{ item }">
                    {{ timeParser(item.endtime) }}
                    <v-btn icon @click="updateDurationDate(item)"
                      ><v-icon small>mdi-circle-edit-outline</v-icon></v-btn
                    >
                  </template>
                </v-data-table>
              </v-card>
            </v-tab-item>

            <v-tab-item v-for="(n, idx) in tabs" :key="`tab-${idx}`">
              <v-card class="ml-auto mr-auto" width="90%" flat>
                <v-card-text>
                  <licences-devices
                    :userDevices="userDevices[idx]"
                    :licenceName="licenceName"
                    :index="idx"
                    @callSyncUserDevices="syncUserLicences"
                  ></licences-devices>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </template>
      </v-card>
    </v-bottom-sheet>

    <v-dialog persistent v-model="showEditDurationDateDialog" width="500">
      <v-card :loading="loading">
        <v-form
          lazy-validation
          v-model="form"
          ref="updateDurationLicenceForm"
          :disable="loading"
        >
          <v-card-text>
            <div class="font-weight-medium mb-2">Update Renewal Date</div>
            <v-row>
              <v-col cols="6">
                <div>
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="getEndDate"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="getEndDate"
                        label="Renewal Date"
                        hint="MM/DD/YYYY format"
                        outlined
                        persistent-hint
                        append-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      :allowed-dates="disablePastDates"
                      v-model="getEndDate"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(getEndDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="duration"
                  :rules="[rules.required, rules.mustMoreThanZero]"
                  auto-select-first
                  label="*Duration"
                  color="deep-purple"
                  type="number"
                  outlined
                  class="inputPrice"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="closeUpdateLicenceDuration" color="secondary">
                CANCEL
              </v-btn>
              <v-btn
                @click="updateLicenceDurationDate"
                :disabled="!form || loading"
                color="primary"
              >
                UPDATE
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>

    <upgrade-user-licence
      @closeUpgradeLicenceUserDialog="closeUpgradeUserLicence"
      :licenceKeys="licenceKeys"
      :showUpgradeLicenceUserDialog="showUpgradeLicenceUserDialog"
      :currentLicence="currentLicence"
    >
    </upgrade-user-licence>

    <assign-licence-form
      :licenceKeys="licenceKeys"
      :showAssignLicenceUserDialog="showAssignLicenceUserDialog"
      :selectedUser="currentUser"
      @closeShowAssignLicenceDialog="showAssignLicenceUserDialog = false"
      @callSyncUserLicences="syncUserLicences"
    ></assign-licence-form>

    <licence-key-feature
      @closeLicenceKeyFeature="showLicenceFeaturesDialog = false"
      @callSyncUserLicences="syncUserLicences"
      :showLicenceFeaturesDialog="showLicenceFeaturesDialog"
      :licenceName="licenceName"
      :features="features"
    ></licence-key-feature>

    <generated-licences
      @closeGeneratedKeysDialog="showGeneratedKeysDialog = false"
      :showGeneratedKeysDialog="showGeneratedKeysDialog"
      :licenceName="licenceName"
      :generatedLicenceKeys="generatedLicenceKeys"
    ></generated-licences>

    <subscription-details
      @closeSubscriptionDetailsDialog="showSubscriptionDetailsDialog = false"
      :showSubscriptionDetailsDialog="showSubscriptionDetailsDialog"
      :subscriptionDetails="subscriptionDetails"
      :licenceName="licenceName"
      :licenceEndtime="licenceEndtime"
    ></subscription-details>

    <device-info-for-delete-licence
      :licenceForDevicesInfo="licenceForDevicesInfo"
      :devicesInfoForDeleteLicence="devicesInfoForDeleteLicence"
      @closeDevicesInfoForDeleteLicence="devicesInfoForDeleteLicence = false"
      @callSyncUserLicences="syncUserLicences"
    ></device-info-for-delete-licence>
  </div>
</template>

<script>
import Vue from 'vue'
import converter from '@/mixins/converter'
import _ from 'lodash'
import { apiService } from '../../../services/api.service'
import AssignLicenceForm from '../licences/AssignLicenceForm.vue'
import LicenceKeyFeature from '../features/LicenceKeyFeature.vue'
import Swal from 'sweetalert2'
import GeneratedLicences from '../licences/GeneratedLicences.vue'
import SubscriptionDetails from '../licences/SubscriptionDetails.vue'
import UpgradeUserLicence from '../licences/UpgradeUserLicence.vue'
import DeviceInfoForDeleteLicence from '../licences/DeviceInfoForDeleteLicence.vue'
import LicencesDevices from '../licences/LicencesDevices.vue'
export default Vue.extend({
  components: {
    AssignLicenceForm,
    LicenceKeyFeature,
    GeneratedLicences,
    SubscriptionDetails,
    UpgradeUserLicence,
    DeviceInfoForDeleteLicence,
    LicencesDevices
  },
  mixins: [converter],
  props: {
    userLicencesDialog: { type: Boolean, required: true },
    userLicences: { type: Array, required: true },
    currentUser: { type: Object, required: true }
  },
  created () {
    this.initializeGetLicenceKeys()
  },
  data () {
    return {
      getUserLicenceLoading: false,
      showUserDevices: false,
      showAssignLicenceUserDialog: false,
      showLicenceFeaturesDialog: false,
      showGeneratedKeysDialog: false,
      showSubscriptionDetailsDialog: false,
      showUpgradeLicenceUserDialog: false,
      showEditDurationDateDialog: false,
      devicesInfoForDeleteLicence: false,
      searchUserLicence: '',
      licenceName: '',
      licenceEndtime: '',
      duration: null,
      getEndDate: null,
      menu: false,
      sheet: false,
      loading: false,
      form: true,
      tab: null,
      counter: 0,
      currentLicence: {},
      subscriptionDetails: {},
      selectedLicence: {},
      licenceForDevicesInfo: {},
      devicesToBeDeleted: [],
      userLicenceInfoForEdit: {},
      licenceKeys: [],
      userDevices: [],
      features: [],
      generatedLicenceKeys: [],
      tabs: [
        {
          id: null,
          label: 'Licence Table',
          canClose: false
        }
      ],
      headers: [
        {
          text: 'Name',
          value: 'label',
          color: 'red',
          align: 'left'
        },
        { text: 'Status', value: 'is_cancelled', align: 'center' },
        { text: 'Started Date', value: 'started', align: 'center' },
        { text: 'Duration Date', value: 'endtime', align: 'center' },
        { text: 'Licence Usages', value: 'limit', align: 'center' },
        { text: 'Features', value: 'features', align: 'center' },
        {
          text: 'Generated Licences',
          value: 'generated_licences',
          align: 'center'
        },
        {
          text: 'Subscription Details',
          value: 'subscriptions',
          align: 'center'
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center'
        }
      ],

      rules: {
        required: (v) => !!v || 'This field is required',
        mustMoreThanZero: (v) => {
          if (!isNaN(v) && v > 0) return true
          return 'Number must be more or equal 0'
        }
      }
    }
  },

  watch: {
    tabs (val) {
      this.tab = val.length - 1
    },
    getEndDate: {
      handler (n) {
        this.watchGetEndDate(n)
      }
    }
  },

  methods: {
    async initializeGetLicenceKeys () {
      await apiService
        .get('admin-get-licences')
        .then((res) => {
          this.licenceKeys = res.data.data
        })
        .catch((err) => {
          console.log('ERR', err.response)
        })
    },

    device_sheet (item) {
      this.licenceName = item.label
      this.open_sheet(item)
    },

    async open_sheet (item) {
      if (item.length === 0) {
        this.sheet = false
      } else {
        this.showUserDevices = true
        this.sheet = true
        if (item.id && !_.find(this.tabs, { id: item.id })) {
          this.userDevices.splice(item.devices.length + 1, 0, item.devices)
          this.tabs.push({
            id: item.id,
            label: item?.label,
            canClose: true
          })
        } else {
          const index = _.findIndex(
            this.tabs,
            (e) => {
              return e.id === item.id
            },
            0
          )
          this.tab = index
        }
      }
    },

    upgradeLicence (item) {
      this.currentLicence = item
      this.showUpgradeLicenceUserDialog = true
    },

    watchGetEndDate (date) {
      const convertedDate = new Date(date)
      const durationDay = this.calculateExpireTime(convertedDate.getTime())
      this.duration = durationDay
    },

    updateDurationDate (item) {
      this.showEditDurationDateDialog = true
      this.selectedLicence = item

      const convertedDate = new Date(parseInt(item.endtime) * 1000)
      const month = convertedDate.getUTCMonth() + 1
      const day = convertedDate.getUTCDate()
      const year = convertedDate.getUTCFullYear()

      const newdate = year + '-' + month + '-' + day
      this.getEndDate = newdate
    },

    async updateLicenceDurationDate () {
      if (this.$refs.updateDurationLicenceForm.validate()) {
        const updateLicenceDurationPayload = {
          user_licence_id: this.selectedLicence.id,
          duration: parseInt(this.duration)
        }
        await apiService
          .put('admin-edit-user-licence', updateLicenceDurationPayload)
          .then(() => {
            this.closeUpdateLicenceDuration()
            this.syncUserLicences()
            Swal.fire(
              'Updated!',
              'Licence renewal time is updated!',
              'success'
            )
          })
          .catch((err) => {
            console.log('ERR', err.response)
            Swal.fire(
              'Error!',
              'An error occurred while updating the license renewal time!',
              'error'
            )
          })
      }
    },

    deleteLicence (licence) {
      const deleteLicencePayload = {
        user_id: licence.user_id,
        licence_id: licence.id
      }
      if (licence.devices.length > 0) {
        this.devicesInfoForDeleteLicence = true
        this.licenceForDevicesInfo = licence
      } else {
        deleteLicencePayload.client_ids = []
        Swal.fire({
          title: 'Are you sure?',
          text: 'The license will be permanently deleted. You won\'t be able to revert this!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
          if (result.value) {
            await apiService
              .post(
                'admin-delete-permanently-user-licence',
                deleteLicencePayload
              )
              .catch((err) => {
                Swal.showValidationMessage(`Request failed: ${err}`)
              })
            this.syncUserLicences()
            Swal.fire('Deleted!', 'Licence has been deleted.', 'success')
          }
        })
      }
    },

    getFeatureLimit (features, licenceName) {
      this.features = features
      this.licenceName = licenceName
      this.showLicenceFeaturesDialog = true
    },

    getUserLicenceKeys (generatedKeys, licenceName) {
      this.generatedLicenceKeys = generatedKeys
      this.licenceName = licenceName
      this.showGeneratedKeysDialog = true
    },

    getSubscriptionDetails (subscription, licence) {
      this.subscriptionDetails = subscription
      this.licenceName = licence.label
      this.licenceEndtime = licence.endtime
      this.showSubscriptionDetailsDialog = true
    },

    closeUpdateLicenceDuration () {
      this.showEditDurationDateDialog = false
      this.$refs.updateDurationLicenceForm.reset()
    },

    closeUpgradeUserLicence () {
      this.$emit('sync-user-licences', this.currentUser.id)
      this.showUpgradeLicenceUserDialog = false
    },

    openAssignLicenceDialog () {
      this.showAssignLicenceUserDialog = true
    },

    syncUserLicences () {
      this.$emit('sync-user-licences', this.currentUser.id)
    },

    disablePastDates (val) {
      return val >= new Date().toISOString().substr(0, 10)
    },

    closeTab (index, include = null) {
      this.tabs.splice(index, 1)
      if (include === 'devices') {
        this.userDevices = this.deleteRow(this.userDevices, index)
      }
    },

    deleteRow (arr, row) {
      arr = arr.slice(0) // make copy
      arr.splice(row - 1, 1)
      return arr
    },

    close () {
      this.$emit('close-userLicence-dialog')
    }
  }
})
</script>

<style>
</style>
