<template>
  <v-container class="ma-0 pa-0"
    ><v-row class="mx-1 d-flex justify-space-between align-center"
      ><v-col cols="8"
        ><p class="text-h5">
          You should give your model and label names correctly to let us run
          your processes successfully. You can upload your models and
          corresponding labels as
          <strong>Json</strong> or <strong>Text</strong>.
        </p> </v-col
      ><v-col cols="3"
        ><v-switch
          v-model="switche"
          inset
          :label="`Input Type: ${!!switche === true ? 'Text' : 'Json'}`"
        ></v-switch
      ></v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row v-if="switche" class="mx-1">
      <v-col cols="12"
        ><p class="text-h6">
          * Label field should be filled as one label per line. Example
          model-label input is given below.
        </p></v-col
      >
      <v-col cols="12">
        <text-input
          v-for="(model, index) in models"
          :key="index"
          v-model="models[index]"
          @delete-row="models.splice(index, 1)"
        >
        </text-input>
      </v-col>
      <v-col cols="6"
        ><v-btn @click="addRow" color="primary"
          ><v-icon>mdi-plus</v-icon> Add New Model
        </v-btn></v-col
      >
    </v-row>
    <v-row v-else no-gutters class="mx-2">
      <v-col cols="11">
        <p class="text-h6">
          * Example model-label structure is given below. You can override it.
        </p></v-col
      >
      <!-- <v-col cols=" 12">
        {{ json }}
        <json-editor-vue v-model="json"></json-editor-vue>
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
import TextInput from './TextInput.vue'

export default {
  components: { TextInput },
  name: 'ModelLabelInput',

  data () {
    return {
      // json is filled with example data
      json: {
        models: [
          {
            model: 'AGENET',
            labels: ['YOUNG', 'MIDDLE', 'OLD']
          },
          {
            model: 'GENDERNET',
            labels: ['MALE', 'FEMALE']
          }
        ]
      },

      // models is filled with example data
      models: [{ labels: 'YOUNG\nMIDDLE\nOLD', model: 'AGENET' }],
      switche: false,
      isJsonValid: true
    }
  },
  methods: {
    addRow () {
      this.models.push({ labels: '', model: '' })
    }
  },
  watch: {
    json: {
      handler: function (n) {
        this.$emit('update-json', n)
      },
      immediate: true,
      deep: true
    },
    models: {
      handler: function (newValue) {
        this.$emit('update-models', newValue)
      },
      deep: true,
      immediate: true
    },
    switche: {
      handler: function (newValue) {
        this.$emit('update-switch', newValue)
      },
      immediate: true
    }
  }
}
</script>

<style></style>
