<template>
  <v-dialog
    v-model="showCompatibleDeviceDialog"
    max-width="500"
    persistent
  >
    <v-card>
      <v-toolbar outlined>
        <span class="text-h6">New Compatible Device </span>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeCompatibleDeviceDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <div class="pt-4">
        <v-form lazy-validation v-model="valid" ref="compatibleDeviceForm">
          <v-card-title class="py-1"> Compatible Device Name </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="compatibleDeviceName"
              class="py-1"
              hide-details="auto"
              clearable
              prepend-inner-icon="mdi-rename"
              required
              :rules="rules"
              outlined
              label="Compatible Device Name"
            ></v-text-field>
          </v-card-text>
          <v-card-title class="py-1"> Chipset ID </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="chipsetId"
              class="py-1 inputChipset"
              type="number"
              hide-details="auto"
              clearable
              prepend-inner-icon="mdi-door"
              required
              :rules="rules"
              outlined
              label="Chipset ID"
              hint="Chipset ID is unique. Please check the chipset ID in device that you add."
            ></v-text-field>
          </v-card-text>

          <v-card-text class="justify-end d-flex">
            <v-btn
              color="primary"
              @click="saveData"
              :disabled="!valid || loading"
              :loading="loading"
            >
              Save
            </v-btn>
          </v-card-text>
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import Swal from 'sweetalert2'
import { apiService } from '../../../services/api.service'
export default {
  data () {
    return {
      rules: [(v) => !!v || 'Field is required'],
      compatibleDeviceName: '',
      chipsetId: null,
      loading: false,
      valid: true
    }
  },

  props: {
    showCompatibleDeviceDialog: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    saveData: function () {
      if (this.$refs.compatibleDeviceForm.validate()) {
        this.loading = true
        const compatibleDevicePayload = {
          name: this.compatibleDeviceName,
          chipset_id: this.chipsetId
        }

        apiService
          .post('admin/new-jetson-device', compatibleDevicePayload)
          .then(() => {
            const form = this.$refs.compatibleDeviceForm
            if (form) {
              form.reset()
              form.resetValidation()
            }
            this.closeCompatibleDeviceDialog()
            this.$store.dispatch('getDockers')
            Swal.fire('Success!', 'Compatible device added.', 'success')
          })
          .catch((err) => {
            Swal.fire('Warning!', `${err.response.data.message}`, 'warning')
            console.error(err.response)
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    closeCompatibleDeviceDialog () {
      this.$refs.compatibleDeviceForm.reset()
      this.$emit('close-compatible-device')
    }
  }
}
</script>

<style>
.inputChipset input[type='number'] {
  -moz-appearance: textfield;
}

.inputChipset input::-webkit-outer-spin-button,
.inputChipset input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>
