<template>
  <v-card :loading="usersLoading">
    <v-card-title color="rgba(0, 0, 0, 0)" class="font-weight-regular" flat>
      Users
      <v-divider class="ml-2" vertical></v-divider>
      <v-btn @click="initialize" icon>
        <v-icon dark>mdi-sync</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        class="mr-2"
        clearable
        dense
        hide-details
        label="Search users"
        outlined
        prepend-inner-icon="mdi-magnify"
      ></v-text-field>
      <v-spacer></v-spacer>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="exportData" color="primary" v-bind="attrs" v-on="on" icon>
            <v-icon dark>mdi-cloud-download</v-icon>
          </v-btn>
        </template>
        <span>Export as pdf</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="orange" v-bind="attrs" v-on="on" icon>
            <download-excel
              class="btn"
              :data="users"
              :fields="json_fields"
              worksheet="My Worksheet"
              name="filename.xls"
            >
            <v-icon>mdi-file-excel</v-icon>
            </download-excel>
          </v-btn>
        </template>
        <span>Export as excel</span>
      </v-tooltip>
      <!-- <register
        @call-users="initialize"
        v-on:register="dialogRegister = false"
      ></register> -->
    </v-card-title>
    <v-card-text>
      <edit-user :dialog="dialog" :user="getUser" v-on:saved="initialize">
      </edit-user>

      <v-data-table
        id="users-table"
        dense
        :headers="headers"
        :items="users"
        :search="search"
        :items-per-page="30"
        :footer-props="{
          'items-per-page-options': [10, 30, 50, -1],
        }"
      >
        <template v-slot:[`item.is_active`]="{ item }">
          <v-icon :color="item.is_active ? 'green' : 'red'">
            mdi-{{ item.is_active ? 'check' : 'minus' }}
          </v-icon>
        </template>

        <template v-slot:[`item.deleted`]="{ item }">
          <v-icon :color="item.deleted ? 'red' : 'grey'">
            mdi-{{ item.deleted ? 'check' : 'minus' }}
          </v-icon>
          <br />
          <span v-if="item.deleted && item.deleted_date" class="red--text">
            {{ timeParserDate(item.deleted_date) }}
          </span>
        </template>

        <template v-slot:[`item.email_confirmed_at`]="{ item }">
          <span>
            {{
              item.email_confirmed_at
                ? timeParserDate(item.email_confirmed_at)
                : 'NO CONFIRMED'
            }}
          </span>
        </template>

        <template v-slot:[`item.last_login`]="{ item }">
          <span>
            {{ item.last_login ? timeParserDate(item.last_login) : 'NO LOGIN' }}
          </span>
        </template>

        <template v-slot:[`item.blocked`]="{ item }">
          <v-icon :color="item.blocked ? 'red' : 'grey'">
            mdi-{{ item.blocked ? 'check' : 'minus' }}
          </v-icon>
          <br />
          <span v-if="item.blocked && item.blocked_date" class="red--text">
            {{ timeParserDate(item.blocked_date) }}
          </span>
        </template>

        <template v-slot:[`item.dev`]="{ item }">
          <v-icon :color="item.dev ? 'red' : 'grey'">
            mdi-{{ item.dev ? 'check' : 'minus' }}
          </v-icon>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <user-actions-menu
            @call-users="initialize"
            :userSource="item"
          ></user-actions-menu>
          <!-- <v-btn v-for="(action, idx) in actionButtons" v-if="checkCondition(item, action.condition)" :key="idx" icon
            @click="action.func(item.id)">
            <v-icon :color="action.color || 'black'">{{ action.icon }}</v-icon>
          </v-btn> -->
        </template>

        <template v-slot:[`item.licences`]="{ item }">
          <v-btn @click="showUserLicencesTable(item)" small color="indigo" dark
            >See Licences
          </v-btn>
        </template>
      </v-data-table>

      <user-licences-card
        :userLicencesDialog="showUserLicencesDialog"
        :userLicences="userLicences"
        :currentUser="currentUser"
        @close-userLicence-dialog="showUserLicencesDialog = false"
        @sync-user-licences="getUserLicences"
      ></user-licences-card>
    </v-card-text>
  </v-card>
</template>

<script>
import Swal from 'sweetalert2'
import Vue from 'vue'
import editUser from '../components/users/UserEdit.vue'
import { apiService } from '../../services/api.service'
import find from 'lodash/find'
import converter from '@/mixins/converter'
import UserLicencesCard from '../components/users/UserLicencesCard.vue'
import UserActionsMenu from '../components/users/UserActionsMenu.vue'
import JsPdf from 'jspdf'
import autoTable from 'jspdf-autotable'
import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)
// import Register from '../components/users/UserRegister.vue'

export default Vue.extend({
  name: 'Users',
  mixins: [converter],
  data () {
    return {
      editDialog: false,
      usersLoading: false,
      dialogRegister: false,
      showUserLicencesDialog: false,
      currentUser: {},
      color: 'red',
      search: null,
      userLicences: [],
      users: [],
      licences: [],
      licencesFeatures: [],
      actionButtons: [
        {
          icon: 'mdi-account-edit-outline',
          func: this.editUser
        },
        {
          icon: 'mdi-delete-outline',
          color: 'red',
          func: this.deleteUser,
          condition: {
            deleted: false
          }
        }
      ],
      getUser: {},
      dialog: {
        condition: false,
        register: false
      },
      json_fields: {
        Name: 'name',
        Email: 'email',
        Active: 'is_active',
        Deleted: 'deleted',
        'Email Confirmed Time': 'email_confirmed_at',
        Blocked: 'blocked',
        'Last Login': 'last_login'
      },
      headers: [
        { text: 'ID', value: 'id', width: 200 },
        { text: 'Name', value: 'name', width: 200 },
        { text: 'Email', value: 'email', width: 200 },
        { text: 'Active', value: 'is_active', width: 200 },
        { text: 'Deleted', value: 'deleted', width: 200 },
        {
          text: 'Email Confirmed Time',
          value: 'email_confirmed_at',
          width: 200
        },
        { text: 'Blocked', value: 'blocked', width: 200 },
        { text: 'Last Login', value: 'last_login', width: 200 },
        { text: 'Licences', value: 'licences', align: 'center' },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          width: 150,
          align: 'center'
        }
      ]
    }
  },

  mounted () {
    this.initialize()
  },

  components: {
    editUser,
    UserLicencesCard,
    UserActionsMenu
    // Register
  },

  methods: {
    exportData () {
      const doc = new JsPdf()
      autoTable(doc, {
        head: [
          [
            'No',
            'Name',
            'Email',
            'ID',
            'Active',
            'Deleted',
            'Email Confirmed Time',
            'Blocked',
            'Last Login'
          ]
        ],
        body: this.users.map((user, index) => {
          return [
            index + 1,
            user.name,
            user.email,
            user.id,
            user.is_active,
            user.deleted,
            user.email_confirmed_at,
            user.blocked,
            user.last_login
          ]
        })
      })
      doc.save('users-table.pdf')
    },
    async initialize () {
      this.usersLoading = true
      await apiService
        .get('admin-get-users')
        .then((response) => {
          this.users = response.data.data
        })
        .finally(() => (this.usersLoading = false))
    },

    showUserLicencesTable (user) {
      this.showUserLicencesDialog = true
      this.currentUser = user
      this.getUserLicences(user.id)
    },

    async getUserLicences (userId) {
      await apiService
        .get(`admin-user-licences/${userId}`)
        .then((res) => {
          this.userLicences = res.data.data
        })
        .catch((err) => {
          console.log(err.response)
        })
    },

    editUser (userId) {
      this.dialog.condition = true
      this.getUser = find(this.users, { id: userId })
    },

    deleteUser (userId) {
      this.usersLoading = true
      Swal.fire({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        async preConfirm () {
          return apiService.put(`users/delete/?id=${userId}`).catch((err) => {
            Swal.showValidationMessage(`Request failed: ${err}`)
          })
        },
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !Swal.isLoading()
      })
        .then(async (result) => {
          if (result.value) {
            this.initialize()
            Swal.fire('Deleted!', 'Your file has been deleted.', 'success')
          }
        })
        .finally(() => (this.usersLoading = false))
    },
    checkCondition (user, condition) {
      if (!condition) return true
      let finalDecision = true
      for (let index = 0; index < Object.keys(condition).length; index++) {
        const cond = Object.keys(condition)[index]
        if (user[cond] !== condition[cond]) {
          finalDecision = false
          break
        }
      }
      return finalDecision
    }
  }
})
</script>

<style scoped>
</style>
