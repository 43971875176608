<template>
  <v-data-table
    v-model="selectedDeviceModels"
    :loading="loading"
    dense
    selected
    :headers="headers"
    :items="jetsonDevices"
    :search="search"
    item-key="id"
    show-select
    class="elevation-0 mb-0 pb-0"
    :items-per-page="itemsPerPage"
    @toggle-select-all="selectAll"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Devices/Jetpack</v-toolbar-title>
        <v-btn
          color="primary"
          @click="getDatatableData"
          :loading="loading"
          icon
        >
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          dense
        ></v-text-field>
      </v-toolbar>
    </template>

    <template v-slot:body="props">
      <tr
        v-for="item in props.items"
        :key="item.id"
        v-show="item.eeprom_id !== 0"
      >
        <td>
          <v-checkbox
            v-model="item.selected"
            @click="selectCheckbox(item)"
            :value="item.selected"
          ></v-checkbox>
        </td>
        <td v-for="header in headers" :key="header.value.id">
          <code
            v-if="header.value.id === '0'"
            class="text-uppercase text-center"
          >
            {{ item.name }}
          </code>
          <v-checkbox
            v-else
            v-model="item.jetpackVersions"
            :value="header.value.id"
            @click="add(item, header)"
          ></v-checkbox>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>
<script>
import _ from 'lodash'
import { apiService } from '../../../services/api.service'
import Swal from 'sweetalert2'
export default {
  data () {
    return {
      selectedDeviceModels: [],
      jetsonDevices: [],
      loading: false,
      isSelected: false,
      search: '',
      itemsPerPage: 10,
      headers: [],
      clonedJetsonDevices: []
    }
  },
  props: {
    deviceModelsProp: {
      type: Array,
      default: () => []
    },
    isEditProcess: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    this.getDatatableData()
  },
  created () {
    this.getDatatableData()
  },
  watch: {
    selectedDeviceModels: {
      handler (n) {
        this.$emit('emited-selected-device', n)
      },
      deep: true
    }
  },
  methods: {
    add (jetson, jetpack) {
      if (!_.some(this.selectedDeviceModels, { id: jetson.id })) {
        jetson.selected = true
        this.selectedDeviceModels.push(jetson)
      }
      const currentJetson = this.findObject(jetson)
      const found = currentJetson.jetpackVersions.some(
        (el) => el.id === jetpack.value.id
      )

      if (found) {
        currentJetson.jetpackVersions = currentJetson.jetpackVersions.filter(
          (el) => el.id !== jetpack.value.id
        )
      }

      if (currentJetson.jetpackVersions.length === 0) {
        this.selectedDeviceModels = this.selectedDeviceModels.filter(
          (el) => el.id !== jetson.id
        )
        currentJetson.selected = false
      }
    },

    findObject (j) {
      return this.selectedDeviceModels.find((obj) => obj.id === j.id)
    },
    selectCheckbox (item) {
      // add all jetpack versions ids in selected (item) jetson
      if (item.selected) {
        for (const a of this.headers) {
          if (a.value.id === '0') {
            continue
          }
          const found = item.jetpackVersions.some((el) => el.id === a.value.id)
          if (!found) {
            item.jetpackVersions.push(a.value.id)
          }
        }

        const currentJetson = this.findObject(item)
        if (currentJetson) {
          currentJetson.jetpackVersions = item.jetpackVersions
        } else {
          this.selectedDeviceModels.push(item)
        }
      } else {
        this.selectedDeviceModels = this.selectedDeviceModels.filter(
          (el) => el.id !== item.id
        )
        item.selected = false
        item.jetpackVersions = []
      }
    },
    selectAll (event) {
      if (event.value) {
        for (const jetson of this.jetsonDevices) {
          jetson.selected = true
          for (const a of this.headers) {
            if (a.value.id === '0') {
              continue
            }
            const found = jetson.jetpackVersions.some(
              (el) => el.id === a.value.id
            )
            if (!found) {
              jetson.jetpackVersions.push(a.value.id)
            }
          }
        }
      } else {
        this.selectedDeviceModels = []
        for (const jetson of this.jetsonDevices) {
          jetson.selected = false
          jetson.jetpackVersions = []
        }
      }
    },
    async getDatatableData () {
      this.loading = true
      apiService
        .get('admin/engine-supported-devices')
        .then((response) => {
          this.headers = response.data.res.headers
          if (this.isEditProcess) {
            this.copyEngineSupportDevices()
          } else {
            this.jetsonDevices = response.data.res.jetson_devices
          }
        })
        .catch((error) => {
          console.log('error', error)
          Swal.fire({
            title: 'Error',
            text: 'Error while fetching jetson-jetpack datas',
            icon: 'error',
            button: 'OK'
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    async copyEngineSupportDevices () {
      this.jetsonDevices = this.deviceModelsProp
      this.selectedDeviceModels = this.deviceModelsProp
      this.$emit('emited-selected-device', this.jetsonDevices)
    }
  }
}
</script>
<style>
.v-input__slot {
  justify-content: center;
}
</style>
