<template>
  <v-dialog v-model="showCreateTagView" width="600px" persistent>
    <v-card :loading="loading"
      ><v-toolbar outlined>
        <span class="text-h6">{{ update ? 'Edit' : 'New' }} Tag</span>
        <v-spacer></v-spacer>

        <v-btn icon @click="cancel">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <!-- Edit Mode-->
      <div class="pt-4">
        <v-form lazy-validation v-model="valid" ref="tagForm">
          <v-card-text>
            <v-row>
              <v-col cols="12" class="p-0">
                <v-card-title class="py-1"> Name </v-card-title>
                <v-card-text>
                  <v-text-field
                    class="py-1"
                    v-model="changedTagName"
                    hide-details="auto"
                    :prefix="`${dockerUrl}:`"
                    clearable
                    prepend-inner-icon="mdi-link"
                    :rules="rules"
                    required
                    outlined
                    label="Name of your tag"
                  ></v-text-field>
                </v-card-text>
              </v-col>
              <v-col cols="12" class="p-0">
                <v-card-title class="py-1"> Label </v-card-title>
                <v-card-text>
                  <v-text-field
                    class="py-1"
                    v-model="tagLabel"
                    prepend-inner-icon="mdi-label-outline"
                    hide-details="auto"
                    clearable
                    outlined
                    label="Label for humans to understand"
                  ></v-text-field>
                </v-card-text>
              </v-col>
              <v-col cols="6" class="p-0">
                <v-card-title class="py-1"> Uncompressed Size </v-card-title>
                <v-card-text>
                  <v-text-field
                    class="py-1 inputPrice"
                    v-model.number="uncompressedSize"
                    hide-details="auto"
                    clearable
                    outlined
                    type="number"
                    label="Uncompressed size for tag (BYTE)"
                  ></v-text-field>
                </v-card-text>
              </v-col>

              <v-col cols="6" class="p-0">
                <v-card-title class="py-1"> Created At </v-card-title>
                <v-card-text>
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="createdAt"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="createdAt"
                        label="Custom Date"
                        hint="YYYY-MM-DD format"
                        outlined
                        persistent-hint
                        append-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="createdAt" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(createdAt)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card-text>

          <hr />

          <v-container>
            <v-row align="center" justify="center">
              <v-radio-group v-model="radios" row>
                <v-radio label="Server Workstation" value="radiox86"></v-radio>
                <v-radio label="Jetson" value="radioJetson"></v-radio>
              </v-radio-group>
            </v-row>
          </v-container>

          <div v-if="radios === 'radiox86'">
            <v-card-title class="py-1">Min Driver Version</v-card-title>
            <v-card-text>
              <v-text-field
                outlined
                label="Driver Version"
                chips
                required
                :rules="[rulesDriverVersion]"
                hide-details="auto"
                :prefix="`R`"
                persistent-hint
                clearable
                prepend-inner-icon="mdi-devices"
                hint="Enter valid format: Ex: 460.32.01"
                deletable-chips
                multiple
                small-chips
                v-model="minDriverVersion"
              >
              </v-text-field>
            </v-card-text>
          </div>
          <div v-if="radios === 'radioJetson'">
            <v-card-title class="py-1">Devices</v-card-title>
            <v-card-text>
              <v-autocomplete
                outlined
                label="Compatible Devices"
                chips
                required
                :rules="arrayRules"
                item-text="name"
                item-value="chipset_id"
                hide-details="auto"
                clearable
                prepend-inner-icon="mdi-devices"
                deletable-chips
                multiple
                small-chips
                v-model="selectedDevices"
                :items="allDevices"
              >
              </v-autocomplete>
            </v-card-text>

            <v-card-title class="py-1">Jetpacks</v-card-title>
            <v-card-text class="py-1">
              <v-select
                hide-details="auto"
                label="Supported jetpacks of new tag"
                outlined
                :items="allJetpacks"
                :disabled="checkJetsonSelected"
                required
                :item-text="
                  (item) =>
                    'L4T: ' +
                    item.l4t +
                    ' / ' +
                    'Jetpack Version: ' +
                    item.version
                "
                item-value="id"
                v-model="changedJetpacks"
                small-chips
                multiple
                deletable-chips
                chips
              >
                <template v-slot:selection="{ item }">
                  <v-chip :color="item.color" small>{{
                    'L4T: ' + item.l4t
                  }}</v-chip>
                </template>
                <template v-slot:item="{ item, attrs, on }">
                  <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                    <v-list-item-action>
                      <v-checkbox :input-value="active"></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-row no-gutters align="center">
                          <span>{{ 'L4T: ' + item.l4t }}</span>
                          <v-spacer></v-spacer>
                          <v-chip small color="primary">{{
                            'Jetpack: ' + item.version
                          }}</v-chip>
                        </v-row>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </v-card-text>
          </div>

          <v-card-title class="py-1">Features</v-card-title>

          <v-card-text>
            <v-autocomplete
              outlined
              label="Tell us about what's inside of this environment"
              chips
              required
              :rules="arrayRules"
              item-text="text"
              item-value="id"
              hide-details="auto"
              clearable
              deletable-chips
              multiple
              small-chips
              v-model="changedSelectedVersions"
              :items="generateAllPossibleVersions"
            >
            </v-autocomplete>
          </v-card-text>
          <v-card-text class="justify-end d-flex">
            <v-btn
              color="primary"
              @click="saveData"
              :disabled="!valid || loading"
              :loading="loading"
            >
              {{ update ? 'Update' : 'Save' }}
            </v-btn>
          </v-card-text>
        </v-form>
      </div>
      <div></div>
    </v-card>
  </v-dialog>
</template>

<script>
import converter from '@/mixins/converter'
import _, { map } from 'lodash'
import Swal from 'sweetalert2'
import { apiService } from '../../../services/api.service'
export default converter.extend({
  data: () => ({
    menu: false,
    createdAt: null,
    tagsOverlay: false,
    zIndex: 0,
    changedJetpacks: [],
    changedTagName: null,
    tagLabel: null,
    uncompressedSize: null,
    propList: [],
    valid: true,
    minDriverVersion: null,
    allDevices: [],
    allJetpacks: [],
    radios: null,
    changedSelectedVersions: [],
    selectedDevices: [],
    update: false,
    loading: false,
    rules: [(v) => !!v || 'Field is required'],
    arrayRules: [(v) => v.length > 0 || 'Field is required']
  }),
  props: {
    showCreateTagView: Boolean,
    tag: {
      default: () => false
    },
    dockerId: {
      default: () => null
    },
    dockerUrl: {
      default: () => null
    }
  },

  methods: {
    getCurrentDate () {
      const date = new Date()
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0') // Aylar 0-11 arası olduğu için 1 ekliyoruz
      const day = String(date.getDate()).padStart(2, '0')

      return `${year}-${month}-${day}`
    },
    updateSelectedVersions (event) {
      // Prevent to select two same label with different versions ex: Ubuntu 11.04 and Ubuntu 18.04
      const lastAdded = event[event.length - 1].split(' ')[0]
      for (const index in event.slice(0, event.length - 1)) {
        if (Object.hasOwnProperty.call(event, index)) {
          const versionLabel = event[index].split(' ')[0]

          if (versionLabel === lastAdded) {
            event.splice(index, 1)
            break
          }
        }
      }
      this.selectedVersions = event
      this.$emit('updateSelectedVersions', event)
    },

    rulesDriverVersion (v) {
      if (!v) {
        return 'Field is required'
      }

      var regExp = /[a-zA-Z]/g
      if (regExp.test(v)) {
        return 'Field input must be number'
      } else {
        return true
      }
    },

    checkVersionStatus () {
      if (this.radios === 'radiox86') {
        this.selectedDevices = []
        this.changedJetpacks = []
      } else if (this.radios === 'radioJetson') {
        this.minDriverVersion = null
      }
    },

    fetchPropByParentID (id) {
      var prop = _.filter(this.propList, (prop) => prop.parent_id === id)
      if (prop.length > 0) {
        return prop[0]
      }
      return null
    },
    saveData: async function () {
      this.checkVersionStatus()
      if (this.$refs.tagForm.validate()) {
        this.loading = true
        const data = {
          form: {
            name: this.changedTagName,
            label: this.tagLabel,
            devices: this.selectedDevices,
            uncompressed_size: parseInt(this.uncompressedSize),
            jetpacks: this.changedJetpacks,
            min_driver_version: this.minDriverVersion,
            tagId: null,
            created_at: this.createdAt // timestampe çevirip gönder!
          },
          props: this.changedSelectedVersions,
          dockerId: this.dockerId
        }
        if (this.update) {
          data.form.tagId = this.tag.id
        }
        await apiService
          .post(`admin/docker-tag-${this.update ? 'update' : 'new'}`, data)
          .then((res) => {
            this.$emit('tag-updated')
          })
          .catch((err) => {
            Swal.fire({
              icon: 'error',
              title: 'Error occurred while create&edit docker tag.',
              text: err.response.data.message
            })
            console.log('Create tag error: ', err.response)
            // this.$emit('close')
          })

          .finally(() => {
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'Tag has been created'
            })
            this.$emit('close')
            this.loading = false
          })
      }
    },
    cancel: function () {
      this.$emit('close', false)
    },

    async fetchProps () {
      await apiService.get('admin/docker-props').then((response) => {
        this.propList = response.data.props
      })
    },
    watchGetEndDate (date) {
      const convertedDate = new Date(date)
    },

    convertDate (timestamp) {
      const convertedDate = new Date(parseInt(timestamp) * 1000)
      const month = convertedDate.getUTCMonth() + 1
      const day = convertedDate.getUTCDate()
      const year = convertedDate.getUTCFullYear()

      const newDateFormat = year + '-' + month + '-' + day
      return newDateFormat
    }
  },
  mounted () {
    this.fetchProps()
    this.jetpacksLoading = true
    apiService.get('admin/get-docker-sources').then((response) => {
      this.allJetpacks = response.data.data.jetpacks
      this.allDevices = response.data.data.categories
    })
  },
  watch: {
    showCreateTagView: {
      handler: function (n) {
        if (n) {
          if (this.tag) {
            this.update = true
            this.changedTagName = this.tag.name
            this.changedSelectedVersions = this.tag.prop_exp.map((o) => {
              return o.id
            })
            this.changedJetpacks = this.tag.jetpack.map((o) => {
              return o.id
            })
            this.tagLabel = this.tag.label
            this.uncompressedSize = this.tag.uncompressed_size
            this.minDriverVersion = this.tag.min_driver_version
            this.selectedDevices = this.tag.chipset_ids
            console.log(this.tag.created_at)
            if (this.tag.created_at) {
              this.createdAt = this.convertDate(this.tag.created_at)
            } else {
              this.createdAt = this.getCurrentDate()
            }
          } else {
            this.update = false
            const form = this.$refs.tagForm
            if (form) {
              form.reset()
              form.resetValidation()
            }
          }
          if (this.tag.min_driver_version) {
            this.radios = 'radiox86'
          } else {
            this.radios = 'radioJetson'
          }
        }
      }
    },
    getEndDate: {
      handler (n) {
        this.watchGetEndDate(n)
      }
    }
  },
  computed: {
    checkJetsonSelected () {
      if (this.selectedDevices.length === 0) return true
      return !(
        this.selectedDevices.includes(24) ||
        this.selectedDevices.includes(25) ||
        this.selectedDevices.includes(33)
      )
    },
    generateAllPossibleVersions: function () {
      const generatedAllPossibleVersionsList = []
      this.propList.forEach((prop) => {
        if (prop.parent_id === -1) {
          this.propList.forEach((childProp) => {
            if (childProp.parent_id === prop.id) {
              generatedAllPossibleVersionsList.push({
                id: childProp.id,
                text: prop.setting_name + ' ' + childProp.setting_name
              })
            }
          })
        }
      })
      return generatedAllPossibleVersionsList
    }
  }
})
</script>

<style>
.inputPrice input[type='number'] {
  -moz-appearance: textfield;
}

.inputPrice input::-webkit-outer-spin-button,
.inputPrice input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>
