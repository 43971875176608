<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-title color="rgba(0, 0, 0, 0)" flat>
          <span class="font-weight-regular">Engines</span>
          <v-divider class="ml-2 mr-2" vertical></v-divider>
          <v-btn @click="getEngines()" icon>
            <v-icon dark>mdi-sync</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            class="mr-2"
            clearable
            dense
            hide-details
            label="Search engine.."
            outlined
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
          <v-btn @click="newEngine" outlined color="primary">
            <v-icon left> mdi-plus </v-icon>
            New Engine
          </v-btn>
        </v-card-title>

        <v-data-table
          :items="engines"
          :headers="headers"
          :search="search"
          v-bind="$attrs"
          item-key="docker_url + device + id"
          :items-per-page="30"
        >
          <template v-slot:[`item.id`]="{ item }">
            <span>{{ item.id }}</span>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-if="item.user_access.includes[-1]"
                  class="ml-2"
                  color="primary"
                  size="20"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-star
                </v-icon>
              </template>
              <span>Common Engine</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.client_db_id`]="{ item }">
            <span v-if="item.client_db_id">{{ item.client_db_id }}</span>
            <v-icon v-else> mdi-minus </v-icon>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              @click="editEngine(item)"
              class="mr-2"
              color="primary"
              size="20"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              @click="deleteEngine(item.id, item.label)"
              color="error"
              size="20"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
    <new-engine
      v-if="newEngineDialog"
      @success="getEngines"
      :dialog="newEngineDialog"
      @dialog-status="newEngineDialog = !newEngineDialog"
    ></new-engine>

    <edit-engine
      v-if="editEngineDialog"
      @success="getEngines"
      :dialog="editEngineDialog"
      :engine="editEngineObj"
      @dialog-status="editEngineDialog = !editEngineDialog"
    ></edit-engine>
  </v-row>
</template>

<script >
import { apiService } from '../../../services/api.service'
import Swal from 'sweetalert2'
import NewEngine from './NewEngine.vue'
import EditEngine from './EditEngine.vue'
export default {
  components: { NewEngine, EditEngine },
  async created () {
    await this.getEngines()
  },
  data () {
    return {
      search: '',
      newEngineDialog: false,
      editEngineDialog: false,
      engines: [],
      loading: false,
      editEngineObj: {},
      headers: [
        {
          value: 'id',
          text: 'ID',
          align: 'start'
        },
        {
          value: 'label',
          text: 'Label',
          align: 'center'
        },
        {
          value: 'container_name',
          text: 'Container Name',
          align: 'center'
        },
        {
          value: 'docker_url',
          text: 'Docker URL',
          align: 'center'
        },
        {
          value: 'script_path',
          text: 'Script Path',
          align: 'center'
        },
        {
          value: 'client_db_id',
          text: 'Client ID(db)',
          align: 'center'
        },
        {
          value: 'actions',
          text: 'Actions',
          sortable: false,
          align: 'center'
        }
      ]
    }
  },
  methods: {
    async getEngines () {
      this.loading = true
      await apiService
        .get('/admin/engine')
        .then((response) => {
          this.engines = response.data.engines
        })
        .catch((error) => {
          Swal.fire({
            icon: 'error',
            title: 'Engine List',
            text: 'Something went wrong while get engine!'
          })
          console.log('error', error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    newEngine () {
      this.newEngineDialog = true
    },
    async editEngine (engine) {
      this.editEngineObj = engine
      this.editEngineDialog = true

      await apiService
        .get(`/admin/get-engine-devices/${engine.id}`)
        .then((res) => {
          this.editEngineObj.supported_devices = res.data.res
        })
        .catch((err) => {
          console.log('ERR', err)
          Swal.fire({
            icon: 'error',
            title: 'Engine Edit',
            text: `Something went wrong while get engine!: ${err.response.data.message}`
          })
        })
    },
    deleteEngine (engineId, engineName) {
      Swal.fire({
        title: 'Are you sure?',
        text: `${engineName} engine will be deleted.`,
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          await apiService
            .delete(`/admin/engine/${engineId}`)
            .then((res) => {
              Swal.fire({
                icon: 'success',
                title: 'Deleted',
                text: `${res.data.message}`
              })
              this.getEngines()
            })
            .catch((err) => {
              console.log('ERR', err)
              Swal.fire({
                icon: 'error',
                title: 'Engine Delete',
                text: `Something went wrong while delete engine!: ${err.response.data.message}`
              })
            })
        }
      })
    }
  }
}
</script>

<style>
</style>
