<template>
  <v-bottom-sheet fullscreen persistent v-model="dialog">
    <v-card>
      <v-card-title>
        <span>Edit Engine</span>
        <v-spacer></v-spacer>
        <v-btn @click="handleDialogStatus" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-row>
        <v-col cols="6">
          <v-form lazy-validation ref="engine-form" v-model="valid">
            <v-card-text class="pb-0">
              <v-text-field
                v-model="form.label"
                outlined
                :rules="[rules.required, rules.specialChars]"
                label="Engine Label"
                type="text"
                prepend-icon="mdi-label-outline"
              ></v-text-field>
              <v-text-field
                v-model="selectedImage"
                label="Image"
                outlined
                persistent-hint
                prepend-icon="mdi-docker"
              ></v-text-field>
              <v-text-field
                v-model="form.containerName"
                outlined
                :rules="[rules.required, rules.whiteSpace, rules.specialChars]"
                label="Container Name"
                prepend-icon="mdi-form-textbox"
              >
              </v-text-field>
              <v-text-field
                v-model="form.scriptPath"
                outlined
                :rules="[rules.required, rules.specialChars]"
                label="Script Path"
                prepend-icon="mdi-language-python"
              >
              </v-text-field>

              <v-text-field
                v-model="form.targetPage"
                outlined
                :rules="[rules.required, rules.specialChars]"
                label="Target Page"
                prepend-icon="mdi-format-page-break"
              >
              </v-text-field>
              <model-label-input
                @update-json="updateJson"
                @update-models="updateModels"
                @update-switch="updateSwitch"
              ></model-label-input>
            </v-card-text>

            <v-card-text class="d-flex justify-end">
              <v-btn
                :disabled="formLoading"
                color="secondary"
                @click="handleDialogStatus"
                class="mr-1"
              >
                Close
              </v-btn>
              <v-btn
                class="primary"
                depressed
                @click="saveEngine"
                :disabled="!valid || formLoading || !isJsonValid"
                :loading="formLoading"
              >
                Edit Engine
              </v-btn>
            </v-card-text>
          </v-form>
        </v-col>
        <v-col cols="6">
          <engines-supported-devices
            :isEditProcess="true"
            :deviceModelsProp="engine.supported_devices"
            @emited-selected-device="updateJetsonDeviceMatrix"
          ></engines-supported-devices>
        </v-col>
      </v-row>
    </v-card>
  </v-bottom-sheet>
</template>

<script>
import Swal from 'sweetalert2'
import ModelLabelInput from '../../components/engines/ModelLabelInput.vue'
import { apiService } from '../../../services/api.service'
import EnginesSupportedDevices from './EnginesSupportedDevices.vue'

export default {
  name: 'NewEngine',
  components: { ModelLabelInput, EnginesSupportedDevices },
  computed: {
    isJsonValid () {
      if (typeof this.json === 'object') {
        return true
      } else if (typeof this.json === 'string') {
        try {
          JSON.parse(this.json)
          return true
        } catch (error) {
          return false
        }
      }
      return false
    }
  },
  props: {
    dialog: {
      type: Boolean
    },
    engine: { type: Object, required: true }
  },
  data () {
    return {
      data: {},
      icon: 'mdi-help-circle-outline',
      json: {},
      models: [],
      switche: false,
      valid: true,
      formLoading: false,
      selectedDevice: {},
      selectedImage: '',
      form: {
        label: '',
        scriptPath: '',
        containerName: '',
        targetPage: '',
        supportedDevices: []
      },
      rules: {
        required: (v) => !!v || 'Field is required',
        whiteSpace: (v) =>
          !!v ||
          /^[a-zA-Z0-9_.-]*$/g.test(v) ||
          'Invalid characters. Only lowercase, uppercase, numbers and {.-_} are allowed.',
        specialChars: (value) => {
          // Turkish chars are restricted
          const regex = /['ığüşöçĞÜŞÖÇİ]/g
          return (
            !!value ||
            !regex.test(value) ||
            'Please give a name without special characters'
          )
        }
      }
    }
  },
  created () {
    if (this.engine) {
      this.copyEngine()
    }
  },
  methods: {
    handleDialogStatus () {
      this.$emit('dialog-status', false)
    },
    saveEngine () {
      if (this.$refs['engine-form'].validate()) {
        const modelArray = [...this.models]
        if (this.switche) {
          for (const element of modelArray) {
            element.labels = element.labels.split('\n')
          }
        }
        const data = {
          ...this.form,
          id: this.engine.id,
          dockerUrl: this.selectedImage,
          modelInfo: this.switche ? modelArray : this.json.models
        }
        this.formLoading = true
        apiService
          .put('admin/engine', data)
          .then((res) => {
            this.$emit('success')
            Object.keys(this.form).forEach((key) => {
              this.form[key] = null
            })
            this.selectedDevice = {}
            this.selectedImage = ''
            Swal.fire({
              title: 'Successfully saved!',
              text: 'Engine has been successfully saved. Now you can start an inference with it.',
              icon: 'success'
            })
            this.handleDialogStatus()
            this.$refs['engine-form'].resetValidation()
          })
          .catch((error) => {
            Swal.fire({
              title: 'Engine Saved',
              text: `Engine save failed: ${error.response.data.message}`,
              icon: 'warning'
            })
          })
          .finally(() => {
            this.formLoading = false
          })
      }
    },
    updateJson (json) {
      this.json = json
    },
    updateModels (models) {
      this.models = models
    },
    updateSwitch (switche) {
      this.switche = switche
    },
    updateJetsonDeviceMatrix (device) {
      this.form.supportedDevices = device
    },
    copyEngine () {
      this.form = {
        label: this.engine.label,
        scriptPath: this.engine.script_path,
        containerName: this.engine.container_name,
        targetPage: this.engine.target_page,
        supportedDevices: [...this.engine.supported_devices]
      }
      this.selectedImage = this.engine.docker_url
      this.models = this.engine.model_info
    }
  }
}
</script>

  <style scoped></style>
