<template>
  <v-dialog v-model="showJetpackTable" max-width="600px" persistent>
    <v-card :loading="jetpackLoading">
      <v-card-title color="rgba(0, 0, 0, 0)" flat>
        <span class="font-weight-regular">Jetpack</span>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          class="mr-2"
          clearable
          dense
          hide-details
          label="Search Jetpack"
          outlined
          prepend-inner-icon="mdi-magnifyuserSpesific"
        ></v-text-field>

        <v-spacer></v-spacer>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-2"
              @click="openJetpackModal"
              fab
              small
              dark
              color="blue-grey"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>New jetpack version with l4t</span>
        </v-tooltip>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeJetpack">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="jetpackVersionList"
        :search="search"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <!-- eslint-disable-next-line -->
          <v-btn
            v-for="(action, idx) in actionButtons"
            :key="idx"
            icon
            @click="action.func(item)"
          >
            <v-icon :color="action.color || 'black'">{{ action.icon }}</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <create-jetpack-view
      :showJetpackDialog="showJetpackDialog"
      @close-jetpack="closeJetpackDialog"
      @fetch-jetpack-list="fetchJetpack"
      :showUpdateOrEdit="updateJetpack"
      :jetpackInfo="selectedJetpack"
    >
    </create-jetpack-view>
  </v-dialog>
</template>

<script>
import converter from '@/mixins/converter'
import Swal from 'sweetalert2'
import { apiService } from '../../../services/api.service'
import CreateJetpackView from './CreateJetpackView.vue'
import { mapGetters } from 'vuex'

export default converter.extend({
  computed: {
    ...mapGetters(['jetpackVersionList', 'jetpackLoading'])
  },
  components: { CreateJetpackView },
  data () {
    return {
      loading: false,
      rules: [(v) => !!v || 'Field is required'],
      valid: true,
      showJetpackDialog: false,
      search: null,
      updateJetpack: false,
      selectedJetpack: null,
      actionButtons: [
        {
          icon: 'mdi-pencil-circle-outline',
          color: 'grey',
          func: this.update_jetpack
        },
        {
          icon: 'mdi-delete-outline',
          color: 'red',
          func: this.delete_jetpack,
          condition: {
            deleted: false
          }
        }
      ],
      headers: [
        {
          text: 'ID',
          value: 'id',
          align: 'center'
        },
        {
          text: 'Label',
          value: 'label',
          align: 'center'
        },
        { text: 'Jetpack Version', value: 'version', align: 'center' },
        { text: 'L4T', value: 'l4t', align: 'center' },
        { text: 'Actions', value: 'actions', align: 'center' }
      ]
    }
  },
  props: {
    showJetpackTable: Boolean
  },
  mounted () {
    this.fetchJetpack()
  },

  methods: {
    closeJetpack () {
      this.$emit('close-jetpack-table')
    },

    async fetchJetpack () {
      this.$store.dispatch('getJetpackVersionList')
    },
    openJetpackModal () {
      this.showJetpackDialog = true
      this.updateJetpack = false
    },

    update_jetpack: function (item) {
      this.showJetpackDialog = true
      this.selectedJetpack = item
      this.updateJetpack = true
    },

    async delete_jetpack (item) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You wont be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!'
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            await apiService
              .delete(`admin/jetpack-delete/${item.id}`)
              .catch((err) => {
                Swal.showValidationMessage(`Request failed: ${err}`)
              })
            this.fetchJetpack()
            Swal.fire('Deleted!', 'Your file has been deleted.', 'success')
          }
        })
        .finally(() => (this.deviceLoading = false))
    },

    async edit_jetpack () {
      if (this.$refs.tagForm.validate()) {
        this.loading = true
        const jetpack = {
          label: this.label,
          version: this.version,
          l4t: this.l4t
        }
        await apiService
          .post('dockers/jetpack', jetpack)
          .then((res) => {
            this.$emit('close-jetpack')
            const form = this.$refs.tagForm
            if (form) {
              form.reset()
              form.resetValidation()
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    },

    closeJetpackDialog () {
      this.showJetpackDialog = false
    }
  }
})
</script>

<style>
</style>
