<template>
  <v-row no-gutters class="d-flex justify-space-between"
    ><v-col cols="5">
      <v-text-field
        outlined
        label="Model Name"
        v-model="modelInstance.model"
        counter
        maxlength="50"
        prepend-icon="mdi-application-cog"
      ></v-text-field
    ></v-col>
    <v-col cols="6">
      <v-row no-gutters
        ><v-col cols="11"
          ><v-textarea
            outlined
            label="Labels"
            v-model="modelInstance.labels"
            rows="1"
            class="textarea-max-height"
            auto-grow
          ></v-textarea></v-col
        ><v-col cols="1"
          ><v-btn icon @click="deleteRow" large
            ><v-icon color="red">mdi-close-circle-outline</v-icon></v-btn
          ></v-col
        ></v-row
      >
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'TextModelLabelInput',
  props: {
    value: {
      type: Object
    }
  },

  methods: {
    deleteRow () {
      this.$emit('delete-row')
    }
  },
  computed: {
    modelInstance: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.textarea-max-height::v-deep {
  textarea {
    max-height: 7rem;
    overflow: auto;
  }
}
</style>
